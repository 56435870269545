const messages = {
  _allAllModulesLocales: [
    { value: "Übersicht Module", locale: "de" },
    { value: "All modules", locale: "en" },
    { value: "Vue d’ensemble des modules", locale: "fr" },
    { value: "Panoramica dei moduli", locale: "it" },
  ],
  _allApplyLocales: [
    { value: "Anwenden", locale: "de" },
    { value: "Apply", locale: "en" },
    { value: "Appliquer", locale: "fr" },
    { value: "Applicare", locale: "it" },
  ],
  _allBackToOverviewLocales: [
    { value: "Zurück zur Übersicht", locale: "de" },
    { value: "Back to overview", locale: "en" },
    { value: "Retour à la vue d’ensemble", locale: "fr" },
    { value: "Ritorno alla panoramica", locale: "it" },
  ],
  _allClearFiltersLocales: [
    { value: "Filter löschen", locale: "de" },
    { value: "Clear Filters", locale: "en" },
    { value: "Supprimer le filtre", locale: "fr" },
    { value: "Rimuovere il filtro", locale: "it" },
  ],
  _allClearLocales: [
    { value: "Löschen", locale: "de" },
    { value: "Clear", locale: "en" },
    { value: "Supprimer", locale: "fr" },
    { value: "Rimuovere", locale: "it" },
  ],
  _allClearSearchLocales: [
    { value: "Sucheingabe löschen", locale: "de" },
    { value: "Clear Search", locale: "en" },
    { value: "Supprimer la recherche", locale: "fr" },
    { value: "Rimuovere la ricerca", locale: "it" },
  ],
  _allCloseLocales: [
    { value: "Abbrechen", locale: "de" },
    { value: "Close", locale: "en" },
    { value: "Interrompre", locale: "fr" },
    { value: "Interrompere", locale: "it" },
  ],
  _allClosingDateLocales: [
    { value: "Anmeldeschluss", locale: "de" },
    { value: "Closing Date", locale: "en" },
    { value: "Délai d‘inscription", locale: "fr" },
    { value: "Termine d‘iscrizione", locale: "it" },
  ],
  _allContactLocales: [
    { value: "Kontakt", locale: "de" },
    { value: "Contact", locale: "en" },
    { value: "Contact", locale: "fr" },
    { value: "Contatto", locale: "it" },
  ],
  _allCostLocales: [
    { value: "Kosten", locale: "de" },
    { value: "Cost", locale: "en" },
    { value: "Coût", locale: "fr" },
    { value: "Prezzo", locale: "it" },
  ],
  _allCourseTypeLocales: [
    { value: "Kurstyp", locale: "de" },
    { value: "Course Type", locale: "en" },
    { value: "Type de cours", locale: "fr" },
    { value: "Tipo di corso", locale: "it" },
  ],
  _allCoursePrevLocales: [
    { value: "Vorheriges Modul", locale: "de" },
    { value: "Previous course", locale: "en" },
    { value: "Module précédent", locale: "fr" },
    { value: "Precedente modulo", locale: "it" },
  ],
  _allCourseNextLocales: [
    { value: "Nächstes Modul", locale: "de" },
    { value: "Next course", locale: "en" },
    { value: "Prochain module", locale: "fr" },
    { value: "Prossimo modulo", locale: "it" },
  ],
  _allDateLocales: [
    { value: "Datum und Zeit", locale: "de" },
    { value: "Date", locale: "en" },
    { value: "Date", locale: "fr" },
    { value: "Data", locale: "it" },
  ],
  _allFilterLocales: [
    { value: "Filter", locale: "de" },
    { value: "Filter", locale: "en" },
    { value: "Filtre", locale: "fr" },
    { value: "Filtro", locale: "it" },
  ],
  _allFiltersLocales: [
    { value: "Filter", locale: "de" },
    { value: "Filters", locale: "en" },
    { value: "Filtres", locale: "fr" },
    { value: "Filtri", locale: "it" },
  ],
  _allLocationLocales: [
    { value: "Ort", locale: "de" },
    { value: "Location", locale: "en" },
    { value: "Lieu", locale: "fr" },
    { value: "Luogo", locale: "it" },
  ],
  _allMenuLocales: [
    { value: "Menu", locale: "de" },
    { value: "Menu", locale: "en" },
    { value: "Menu", locale: "fr" },
    { value: "Menu", locale: "it" },
  ],
  _allModuleLocales: [
    { value: "Modul", locale: "de" },
    { value: "Module", locale: "en" },
    { value: "Module", locale: "fr" },
    { value: "Modulo", locale: "it" },
  ],
  _allModulesLocales: [
    { value: "Module", locale: "de" },
    { value: "Modules", locale: "en" },
    { value: "Modules", locale: "fr" },
    { value: "Moduli", locale: "it" },
  ],
  _allNoResultsActionLocales: [
    {
      value:
        "Um mehr Ergebnisse zu erhalten, versuchen Sie einen Ihrer aktiven Filter anzupassen.",
      locale: "de",
    },
    {
      value: "To get more results please try different filters.",
      locale: "en",
    },
    {
      value:
        "Pour trouver d’autres résultats, modifiez les différents filtres.",
      locale: "fr",
    },
    {
      value:
        "Per visualizzare ulteriori risultati, modificare i differenti filtri.",
      locale: "it",
    },
  ],
  _allNoResultsLocales: [
    { value: "Keine Resultate gefunden", locale: "de" },
    { value: "No Results", locale: "en" },
    { value: "Pas de résultat trouvé", locale: "fr" },
    { value: "Nessun risultato", locale: "it" },
  ],
  _allOpenInMapsLocales: [
    { value: "In Google Maps öffnen", locale: "de" },
    { value: "Open in maps", locale: "en" },
    { value: "Ouvrir dans Google Maps", locale: "fr" },
    { value: "Aprire in Google Malas", locale: "it" },
  ],
  _allPartnerLocales: [
    { value: "Partner", locale: "de" },
    { value: "Partner", locale: "en" },
    { value: "Partenaire", locale: "fr" },
    { value: "Partner", locale: "it" },
  ],
  _allRecommendedModulesLocales: [
    { value: "Entdecken Sie unsere beliebtesten Kurse", locale: "de" },
    { value: "Recommended modules", locale: "en" },
    { value: "Découvrez nos cours les plus appréciés ", locale: "fr" },
    { value: "Scoprite i nostri corsi raccomandati", locale: "it" },
  ],
  _allRegisterLocales: [
    { value: "Anmelden", locale: "de" },
    { value: "Register", locale: "en" },
    { value: "S’enregistrer", locale: "fr" },
    { value: "Iscriversi", locale: "it" },
  ],
  _allSearchLocales: [
    { value: "Suche", locale: "de" },
    { value: "Search", locale: "en" },
    { value: "Recherche", locale: "fr" },
    { value: "Ricercare", locale: "it" },
  ],
  _allShowPartnerModulesLocales: [
    { value: "Alle Module von {partner} anzeigen", locale: "de" },
    { value: "Show modules for {partner}", locale: "en" },
    { value: "Montrer tous les modules du {partner}", locale: "fr" },
    { value: "Mostrare tutti i moduli del {partner}", locale: "it" },
  ],
  _allWebsiteLocales: [
    { value: "Website", locale: "de" },
    { value: "Website", locale: "en" },
    { value: "Website", locale: "fr" },
    { value: "Website", locale: "it" },
  ],
  _allReadMoreLocales: [
    { value: "Mehr lesen", locale: "de" },
    { value: "Read More", locale: "en" },
    { value: "En savoir plus", locale: "fr" },
    { value: "Per saperne di più", locale: "it" },
  ],
  _allBlogDateLocales: [
    { value: " Blogbeitrag veröffentlicht am {date}", locale: "de" },
    { value: "Blog Date {date}", locale: "en" },
    { value: "Contributions du blog publiées le {date}", locale: "fr" },
    { value: "Post di blog pubblicato il {date}", locale: "it" },
  ],
  _allShowMoreLocales: [
    { value: "Mehr lesen", locale: "de" },
    { value: "Show more", locale: "en" },
    { value: "Montrer plus", locale: "fr" },
    { value: "Mostrare di più", locale: "it" },
  ],
  _allShowLessLocales: [
    { value: "Weniger anzeigen", locale: "de" },
    { value: "Show less", locale: "en" },
    { value: "Montrer moins", locale: "fr" },
    { value: "Mostrare di meno", locale: "it" },
  ],
  _allRegionLocales: [
    { value: "Region", locale: "de" },
    { value: "Region", locale: "en" },
    { value: "Région", locale: "fr" },
    { value: "Regione", locale: "it" },
  ],
  _allShareLocales: [
    { value: "Teilen", locale: "de" },
    { value: "Share", locale: "en" },
    { value: "Partager", locale: "fr" },
    { value: "Condividere", locale: "it" },
  ],
  _allRelatedBlogPostsLocales: [
    { value: "Ähnliche Beiträge", locale: "de" },
    { value: "Related Blog Posts", locale: "en" },
    { value: "Contributions similaires", locale: "fr" },
    { value: "Post simili", locale: "it" },
  ],
  _allShowAllLocales: [
    { value: "Alles zeigen", locale: "de" },
    { value: "Show all", locale: "en" },
    { value: "Tout montrer", locale: "fr" },
    { value: "Mostrare tutto", locale: "it" },
  ],
  _allResultLocales: [
    { value: "Resultat", locale: "de" },
    { value: "Result", locale: "en" },
    { value: "Résultat", locale: "fr" },
    { value: "Risultato", locale: "it" },
  ],
  _allResultsLocales: [
    { value: "Resultate", locale: "de" },
    { value: "Results", locale: "en" },
    { value: " Résultats", locale: "fr" },
    { value: "Risultati", locale: "it" },
  ],
  _allModuleIdLongLocales: [
    { value: "Modulnummer", locale: "de" },
    { value: "Module number", locale: "en" },
    { value: "Numéro de module", locale: "fr" },
    { value: "Numero del modulo", locale: "it" },
  ],
  _allDurationLocales: [
    { value: "Dauer", locale: "de" },
    { value: "Duration", locale: "en" },
    { value: "Durée", locale: "fr" },
    { value: "Durata", locale: "it" },
  ],
  _allRequirementsLocales: [
    { value: "Voraussetzungen", locale: "de" },
    { value: "Requirements", locale: "en" },
    { value: "Exigences", locale: "fr" },
    { value: "Requisiti", locale: "it" },
  ],
  _allPriceLocales: [
    { value: "Preis", locale: "de" },
    { value: "Price", locale: "en" },
    { value: "Prix", locale: "fr" },
    { value: "Prezzo", locale: "it" },
  ],
  _allPublishedAtLocales: [
    { value: "Veröffentlicht am {date}", locale: "de" },
    { value: "Published {date}", locale: "en" },
    { value: "Contributions publiées le {date}", locale: "fr" },
    { value: "Pubblicato il {date}", locale: "it" },
  ],
  _allGoalsLocales: [
    { value: "Lernziele", locale: "de" },
    { value: "Learning Goals", locale: "en" },
    { value: "Objectifs d'apprentissage", locale: "fr" },
    { value: "Obiettivi di apprendimento", locale: "it" },
  ],
  _allWaitinglistCtaLocales: [
    { value: "In Warteliste eintragen", locale: "de" },
    { value: "Join waiting list", locale: "en" },
    { value: "S'inscrire sur la liste d'attente", locale: "fr" },
    { value: "Registrati in lista d'attesa", locale: "it" },
  ],
  _allWaitinglistAvailableLocales: [
    { value: "Warteliste", locale: "de" },
    { value: "Fully booked, waiting list possible", locale: "en" },
    { value: "Complet, liste d'attente possible", locale: "fr" },
    { value: "Al completo, lista d'attesa possibile", locale: "it" },
  ],
  _allBlogLocales: [
    { value: "Blog", locale: "de" },
    { value: "Blog", locale: "en" },
    { value: "Blog", locale: "fr" },
    { value: "Blog", locale: "it" },
  ],
  _allEventLocales: [
    { value: "Event", locale: "de" },
    { value: "Event", locale: "en" },
    { value: "Event", locale: "fr" },
    { value: "Event", locale: "it" },
  ],
  _allMediaPressLocales: [
    { value: "Pressespiegel", locale: "de" },
    { value: "Press review", locale: "en" },
    { value: "Revue de presse", locale: "fr" },
    { value: "Rassegna stampa", locale: "it" },
  ],
  _allMediaReleaseLocales: [
    { value: "Medienmitteilungen", locale: "de" },
    { value: "Media releases", locale: "en" },
    { value: "Communiqués de presse", locale: "fr" },
    { value: "Comunicati stampa", locale: "it" },
  ],
  _allSelectAllLocales: [
    { value: "Alle auswählen", locale: "de" },
    { value: "Select All", locale: "en" },
    { value: "Sélectionner tout", locale: "fr" },
    { value: "Seleziona tutto", locale: "it" },
  ],
  _allPrintLocales: [
    { value: "Drucken", locale: "de" },
    { value: "Print", locale: "en" },
    { value: "Imprimer", locale: "fr" },
    { value: "Stampa", locale: "it" },
  ],
  _allCostOverviewLocales: [
    { value: "Kostenübersicht", locale: "de" },
    { value: "Cost Overview", locale: "en" },
    { value: "Aperçu des coûts", locale: "fr" },
    { value: "Panoramica dei costi", locale: "it" },
  ],
  _allTotalLocales: [
    { value: "Total", locale: "de" },
    { value: "Total", locale: "en" },
    { value: "Total", locale: "fr" },
    { value: "Totale", locale: "it" },
  ],
  _allOnsiteLocales: [
    { value: "Präsenzkurs", locale: "de" },
    { value: "On-site", locale: "en" },
    { value: "Sur site", locale: "fr" },
    { value: "In sede", locale: "it" },
  ],
  _allRemoteLocales: [
    { value: "Fernstudium", locale: "de" },
    { value: "Remote", locale: "en" },
    { value: "À distance", locale: "fr" },
    { value: "Da remoto", locale: "it" },
  ],
  _allCalculateLocales: [
    { value: "Berechnen Sie den Preis", locale: "de" },
    { value: "Calculate the price", locale: "en" },
    { value: "Calculer le prix", locale: "fr" },
    { value: "Calcolare il prezzo", locale: "it" },
  ],
  _allUseToolLocales: [
    {
      value: "Nutzen Sie dieses Tool, um die Kosten für Kurse zu ermitteln",
      locale: "de",
    },
    { value: "Use this tool to calculate the cost of courses", locale: "en" },
    {
      value: "Utilisez cet outil pour calculer le coût des cours",
      locale: "fr",
    },
    {
      value: "Utilizzate questo strumento per calcolare il costo dei corsi",
      locale: "it",
    },
  ],
  _allShowingLocales: [
    { value: "Zeigt", locale: "de" },
    { value: "Showing", locale: "en" },
    { value: "Montrant", locale: "fr" },
    { value: "Mostrando", locale: "it" },
  ],
  _allEntriesLocales: [
    { value: "Einträge", locale: "de" },
    { value: "Entries", locale: "en" },
    { value: "Entrées", locale: "fr" },
    { value: "Voci", locale: "it" },
  ],
  _allTopicsLocales: [
    { value: "Themengebiete", locale: "de" },
    { value: "", locale: "en" },
    { value: "Domaines thématiques", locale: "fr" },
    { value: "Aree tematiche", locale: "it" },
  ],
  _allFormErrorLocales: [
    {
      value:
        "Es scheint ein Problem mit dem Kontaktformular zu geben. Bitte senden Sie uns direkt eine E-Mail an [email].",
      locale: "de",
    },
    {
      value:
        "We're sorry, but there seems to be an issue with the contact form. Please email us directly at [email].",
      locale: "en",
    },
    {
      value:
        "Nous sommes désolés, mais il semble y avoir un problème avec le formulaire de contact. Veuillez nous envoyer un email directement à [email].",
      locale: "fr",
    },
    {
      value:
        "Ci dispiace, ma sembra esserci un problema con il modulo di contatto. Si prega di inviarci un'email direttamente a [email].",
      locale: "it",
    },
  ],
  _allWebinarLocales: [
    { value: "Webinar", locale: "de" },
    { value: "Webinar", locale: "en" },
    { value: "Webinaire", locale: "fr" },
    { value: "Webinar", locale: "it" },
  ],
};
export default messages;
