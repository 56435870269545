import { useRouter } from "next/router";
import * as React from "react";
import messages from "../../public/messages/messages";
import { Maybe, TranslatedFragmentFragment } from "../generated/graphql";
import { intersperse } from "./array";

export type TranslationKey =
  | "allModules"
  | "apply"
  | "backToOverview"
  | "blog"
  | "blogDate"
  | "calculate"
  | "clear"
  | "clearFilters"
  | "clearSearch"
  | "close"
  | "closingDate"
  | "contact"
  | "cost"
  | "costOverview"
  | "courseNext"
  | "coursePrev"
  | "courseType"
  | "date"
  | "duration"
  | "event"
  | "filter"
  | "filters"
  | "location"
  | "menu"
  | "module"
  | "moduleIdLong"
  | "modules"
  | "noResults"
  | "noResultsAction"
  | "onsite"
  | "openInMaps"
  | "partner"
  | "price"
  | "print"
  | "readMore"
  | "recommendedModules"
  | "region"
  | "register"
  | "relatedBlogPosts"
  | "remote"
  | "requirements"
  | "result"
  | "results"
  | "search"
  | "selectAll"
  | "share"
  | "showAll"
  | "showLess"
  | "showMore"
  | "showPartnerModules"
  | "total"
  | "useTool"
  | "website"
  | "goals"
  | "publishedAt"
  | "waitinglistCta"
  | "waitinglistAvailable"
  | "mediaPress"
  | "mediaRelease"
  | "showing"
  | "entries"
  | "topics"
  | "formError"
  | "webinar";

const regex = /\{([^}]+)\}/g;

/**
 * @FIXME terrible placeholder function, please move along.
 */
export function formatMessage(message: string, variable: string | number) {
  return message.replace(regex, variable.toString());
}

function lowerFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function useMessages() {
  const { locale } = useRouter();
  const allMessages = { ...messages };
  const filtered = React.useMemo(
    () =>
      Object.keys(allMessages).reduce(
        (acc, val) => {
          const match = (allMessages as $FixMe)[val].find((d: $FixMe) => d.locale === locale);
          const newKey = lowerFirstLetter(val.replace(/_all|Locales/g, ""));
          return !match ? acc : { ...acc, [newKey]: match.value };
        },
        {} as Record<TranslationKey, string>,
      ),
    [locale],
  );

  return {
    t: (key: TranslationKey) => filtered[key],
    allMessages: messages,
  };
}

export function formatDate(
  date: string,
  locale: string,
  options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "2-digit", year: "numeric" },
) {
  const timestamp = Date.parse(date);
  if (!isNaN(timestamp)) {
    const d = new Date(timestamp);
    return d.toLocaleDateString(locale, options);
  }
  return date;
}

export function selectFromMultiLocale(
  messages: Array<undefined | Maybe<TranslatedFragmentFragment>> | null | undefined,
  locale: string,
) {
  return (messages || []).find((x) => x?.locale === locale)?.value;
}

export function formatFranc(ammount: number) {
  const isInteger = Number.isInteger(ammount);
  const [integer, decimal] = ammount.toString().split(".");
  const tmp = [...integer]
    .reverse()
    .join("")
    .match(/.{1,3}/g)
    ?.map((x) => [...x].reverse().join(""));
  const withSeparator = tmp && tmp.length > 1 ? intersperse(`'`, tmp).reverse().join("") : tmp![0];
  return isInteger ? `${withSeparator} CHF` : `${withSeparator}.${decimal} CHF`;
}

export const toPascalCase = (string: string) => {
  const [letter, ...rest] = [...string];
  return `${letter.toUpperCase()}${rest.join("")}`;
};
